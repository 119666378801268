import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAddressOption, LazyAllocationOption, LazyAssetAllocationOption, LazyChoiceGroup, LazyCurrencyOption, LazyDateOption, LazyDocuSignLogo, LazyFirstLogo, LazyMultiChoiceAsset, LazyMultiChoiceGroup, LazyPercentOption, LazyPetOption, LazySelectOption, LazyShortInput, LazyYearOption, LazyAlignmentPanel, LazyAlignmentPetPanel, LazyAllocationPanel, LazyOwnershipPanel, LazyAdvisoryLetterConfirm, LazyLawyerCompletePrenupConfirm, LazyLawyerConfirmSendToUser, LazyLawyerOwnConfirm, LazyLawyerPanel, LazyMoreRevisionsModal, LazyOpposingCounselConfirm, LazyConfirmModal, LazyDefaultModal, LazyDetailsModal, LazyOnboardingModal, LazyPartnerDetailsModal, LazyPaymentSuccessModal, LazyAssetPanel, LazyFamilyPanel, LazyInviteStep, LazyTextStep, LazyDisclosureInfoPanel, LazyEducationPanel, LazyEmptyPanel, LazyPrenupDefaultPanel, LazyPlaidPanel, LazySvgo404, LazySvgoAlign, LazySvgoDesktopIllustration, LazySvgoDesktopSignIllustration, LazySvgoGoogle, LazySvgoGroup, LazySvgoHappyFaceIllustration, LazySvgoLawyerReview, LazySvgoListIllustration, LazySvgoLogo, LazySvgoNuxtIcon, LazySvgoOnboarding01, LazySvgoOnboarding02, LazySvgoOnboarding03, LazySvgoOnboarding04, LazySvgoOnboarding05, LazySvgoOnboarding06, LazySvgoOnboarding07, LazySvgoPay, LazySvgoPlaneIllustration, LazySvgoReading, LazySvgoReviewIllustration, LazySvgoShareBasics, LazySvgoSignDoc, LazySvgoTeam, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["AddressOption", LazyAddressOption],
["AllocationOption", LazyAllocationOption],
["AssetAllocationOption", LazyAssetAllocationOption],
["ChoiceGroup", LazyChoiceGroup],
["CurrencyOption", LazyCurrencyOption],
["DateOption", LazyDateOption],
["DocuSignLogo", LazyDocuSignLogo],
["FirstLogo", LazyFirstLogo],
["MultiChoiceAsset", LazyMultiChoiceAsset],
["MultiChoiceGroup", LazyMultiChoiceGroup],
["PercentOption", LazyPercentOption],
["PetOption", LazyPetOption],
["SelectOption", LazySelectOption],
["ShortInput", LazyShortInput],
["YearOption", LazyYearOption],
["AlignmentPanel", LazyAlignmentPanel],
["AlignmentPetPanel", LazyAlignmentPetPanel],
["AllocationPanel", LazyAllocationPanel],
["OwnershipPanel", LazyOwnershipPanel],
["AdvisoryLetterConfirm", LazyAdvisoryLetterConfirm],
["LawyerCompletePrenupConfirm", LazyLawyerCompletePrenupConfirm],
["LawyerConfirmSendToUser", LazyLawyerConfirmSendToUser],
["LawyerOwnConfirm", LazyLawyerOwnConfirm],
["LawyerPanel", LazyLawyerPanel],
["MoreRevisionsModal", LazyMoreRevisionsModal],
["OpposingCounselConfirm", LazyOpposingCounselConfirm],
["ConfirmModal", LazyConfirmModal],
["DefaultModal", LazyDefaultModal],
["DetailsModal", LazyDetailsModal],
["OnboardingModal", LazyOnboardingModal],
["PartnerDetailsModal", LazyPartnerDetailsModal],
["PaymentSuccessModal", LazyPaymentSuccessModal],
["AssetPanel", LazyAssetPanel],
["FamilyPanel", LazyFamilyPanel],
["InviteStep", LazyInviteStep],
["TextStep", LazyTextStep],
["DisclosureInfoPanel", LazyDisclosureInfoPanel],
["EducationPanel", LazyEducationPanel],
["EmptyPanel", LazyEmptyPanel],
["PrenupDefaultPanel", LazyPrenupDefaultPanel],
["PlaidPanel", LazyPlaidPanel],
["Svgo404", LazySvgo404],
["SvgoAlign", LazySvgoAlign],
["SvgoDesktopIllustration", LazySvgoDesktopIllustration],
["SvgoDesktopSignIllustration", LazySvgoDesktopSignIllustration],
["SvgoGoogle", LazySvgoGoogle],
["SvgoGroup", LazySvgoGroup],
["SvgoHappyFaceIllustration", LazySvgoHappyFaceIllustration],
["SvgoLawyerReview", LazySvgoLawyerReview],
["SvgoListIllustration", LazySvgoListIllustration],
["SvgoLogo", LazySvgoLogo],
["SvgoNuxtIcon", LazySvgoNuxtIcon],
["SvgoOnboarding01", LazySvgoOnboarding01],
["SvgoOnboarding02", LazySvgoOnboarding02],
["SvgoOnboarding03", LazySvgoOnboarding03],
["SvgoOnboarding04", LazySvgoOnboarding04],
["SvgoOnboarding05", LazySvgoOnboarding05],
["SvgoOnboarding06", LazySvgoOnboarding06],
["SvgoOnboarding07", LazySvgoOnboarding07],
["SvgoPay", LazySvgoPay],
["SvgoPlaneIllustration", LazySvgoPlaneIllustration],
["SvgoReading", LazySvgoReading],
["SvgoReviewIllustration", LazySvgoReviewIllustration],
["SvgoShareBasics", LazySvgoShareBasics],
["SvgoSignDoc", LazySvgoSignDoc],
["SvgoTeam", LazySvgoTeam],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
