<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'
import {
  useCurrencyInput,
  type CurrencyInputOptions,
  ValueScaling,
  CurrencyDisplay,
} from 'vue-currency-input'

export interface Props {
  context: FormKitFrameworkContext
}

const { context } = defineProps<Props>()
const emit = defineEmits<{ submitInput: [] }>()

const options: CurrencyInputOptions = {
  currency: 'USD',
  currencyDisplay: CurrencyDisplay.symbol,
  precision: 2,
  hideCurrencySymbolOnFocus: false,
  hideGroupingSeparatorOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: true,
  autoDecimalDigits: false,
  valueScaling: ValueScaling.precision,
  useGrouping: true,
  accountingSign: false,
}

const { inputRef, numberValue, setValue } = useCurrencyInput(options, false)

const blur = (): void => {
  context.handlers.blur()
}

watch(
  () => numberValue.value,
  value => {
    context.node.input(value)
  }
)

watch(inputRef, async newValue => {
  if (newValue) {
    await nextTick()
    setValue(context.value)
  }
})
</script>

<template>
  <div
    class="formkit-inner transition-ring mb-1 flex w-full items-center overflow-hidden rounded-lg ring-1 ring-black-10 duration-200 ease-in-out focus-within:ring-2 focus-within:ring-black-80 hover:ring-black-30 focus-within:hover:ring-black-80"
  >
    <input
      ref="inputRef"
      class="formkit-input w-full appearance-none bg-transparent bg-white p-[15px] text-base text-black-100 placeholder-black-60 focus:shadow-none focus:outline-none disabled:bg-black-5 disabled:text-black-30"
      type="text"
      placeholder="$0"
      @blur="blur"
    />
  </div>
</template>
